<template>
  <v-data-table
    class="elevation-1"
    :items="data"
    :headers="computedHeaders"
    :loading="tableLoading"
  >
    <template v-slot:item.actions="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" small class="mr-2" @click="edit(item)">
            {{
              $state.role == "superuser" || item.is_aprovado != null
                ? "mdi-eye"
                : "mdi-pencil"
            }}
          </v-icon>
        </template>
        <span>
          {{
            $state.role == "superuser" || item.is_aprovado != null
              ? "Visualizar"
              : "Editar"
          }}</span
        >
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon
            v-on="on"
            v-if="item.is_aprovado == null || $state.role != 'superuser'"
            small
            @click="remove(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <span>Remover</span>
      </v-tooltip>
    </template>
    <template v-slot:item.preco-medio="{ item }">
      {{
        Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(item.preco_medio)
      }}
    </template>

    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Solicitação de Materiais e Serviços</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>

        <v-dialog v-model="dialog" max-width="600px" @click:outside="close">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-if="$state.role != 'superuser'"
              v-bind="attrs"
              v-on="on"
              >Novo</v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="headline"></span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-row v-if="$state.role != 'eqpdtiuo'">
                      <UOAutoComplete
                        v-model="Item.uo"
                        :error-messages="errors['uo']"
                      />
                    </v-row>
                    <v-row>
                      <CategoriaAutoComplete
                        v-model="Item.categoria"
                        :error-messages="errors['categoria']"
                      />
                    </v-row>
                    <v-row>
                      <NaturezaDespesaAutoComplete
                        v-model="Item.natureza_despesa"
                        :error-messages="errors['natureza_despesa']"
                      />
                    </v-row>
                    <v-row>
                      <v-text-field
                        v-model="Item.nome"
                        label="Nome"
                        :error-messages="errors['nome']"
                      ></v-text-field>
                    </v-row>
                    <v-row>
                      <v-textarea
                        v-model="Item.descricao"
                        label="Descrição"
                        :error-messages="errors['descricao']"
                      ></v-textarea>
                    </v-row>
                    <v-row>
                      <v-money
                        v-model="Item.preco_medio"
                        label="Preço médio"
                        :error-messages="errors['preco_medio']"
                      ></v-money>
                    </v-row>
                    <v-row>
                      <v-text-field
                        v-model="Item.catmat_catser"
                        label="CATMAT/CATSER"
                        :error-messages="errors['catmat_catser']"
                      ></v-text-field>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color=" darken-1" text @click="close">{{
                $state.role == "superuser" ? "Fechar" : "Cancelar"
              }}</v-btn>
              <v-btn
                :loading="saving"
                v-if="$state.role != 'superuser'"
                color="darken-1"
                text
                @click="save"
                >Salvar</v-btn
              >

              <v-btn
                v-if="$state.role == 'superuser'"
                color="lighten-1"
                @click="setStatus(true)"
                text
                >Aprovar</v-btn
              >

              <v-btn
                v-if="$state.role == 'superuser'"
                color="lighten-1"
                @click="setStatus(false)"
                text
                >Rejeitar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
  </v-data-table>
</template>

<script>
import { SolicitacaoItem } from "@/api/eqpdti";
import CategoriaAutoComplete from "@/components/CategoriaAutoComplete";
import NaturezaDespesaAutoComplete from "@/components/NaturezaDespesaAutoComplete";
import UOAutoComplete from "@/components/UOAutoComplete";
import VMoney from "@/components/VMoney";
export default {
  name: "Item",
  components: {
    NaturezaDespesaAutoComplete,
    CategoriaAutoComplete,
    UOAutoComplete,
    "v-money": VMoney,
  },
  data: () => ({
    data: [],
    headers: [
      {
        text: "Nome",
        value: "nome",
        align: "left",
      },
      {
        text: "Preço Médio",
        value: "preco-medio",
        align: "center",
      },
      {
        text: "Situação",
        value: "situacao",
        align: "center",
      },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "end",
      },
    ],
    Item: {
      inicio: "",
      fim: "",
    },
    dialog: false,
    saving: false,
    tableLoading: false,
    errors: [],
  }),
  computed: {
    computedHeaders() {
      this.fetch();
      if (this.$state.role != "eqpdtiuo") {
        return [
          {
            text: "Unidade Organizacional",
            value: "uo_sigla",
            align: "center",
          },
        ].concat(this.headers);
      }
      return this.headers;
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.Item = {};
      });
    },
    setStatus(status) {
      this.Item.is_aprovado = status;
      this.save();
    },
    save() {
      this.saving = true;
      this.errors = [];
      const _then = () => {
        this.saving = false;
        this.close();
        this.fetch();
      };
      const _catch = (error) => {
        this.saving = false;
        this.errors = error.response.data;
        if (this.errors["non_field_errors"]) {
          this.$emitter.emit("alert:error", this.errors["non_field_errors"][0]);
        } else {
          this.$emitter.emit("alert:error", this.errors);
        }
      };

      if (this.Item["id"]) {
        let id = this.Item["id"];

        SolicitacaoItem.update(id, this.Item).then(_then).catch(_catch);
      } else {
        SolicitacaoItem.post(this.Item).then(_then).catch(_catch);
      }
    },
    edit(item) {
      this.Item = Object.assign({}, item);
      this.dialog = true;
    },

    remove(item) {
      if (window.confirm("Deseja realmente excluir?")) {
        this.tableLoading = true;
        SolicitacaoItem.remove(item["id"]).then(() => {
          this.tableLoading = false;
          this.fetch();
        });
      }
    },
    fetch() {
      this.tableLoading = true;
      SolicitacaoItem.list().then((response) => {
        this.data = response.data.map((item) => {
          item.situacao =
            item.is_aprovado === true
              ? "Aprovado"
              : item.is_aprovado === false
              ? "Rejeitado"
              : "Em Análise";
          return item;
        });

        this.tableLoading = false;
      });
    },
  },
  // mounted() {
  //   this.fetch();
  // },
};
</script>
